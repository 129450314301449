import './Website.css';
import img from './profile.jpg';
import bg from './bg.jpeg';

import terraSvg from './img/terra.svg';
import mcontrolSvg from './img/mcontrol.svg';
import scandinodesSvg from './img/scandinodes.svg';

function Website() {
  return (
    <div className="website">
      <img src={bg} alt="background" className="bg" />

      <div className="website__container">
        <img src={img} alt="Profile" className="website__img" />
        <h1 className="website__title">Alessandro Candeago</h1>
        <h2 className="website__subtitle">{new Date(new Date().getTime() - 1078963200000).getUTCFullYear() - 1970}yo React and Blockchain developer</h2>
        <div className="work__experience">
          <h3>My Work Experience</h3>
          <div className="work__experience__container">
            <div className="work__item">
              <a href="https://terra.money/" target="_blank" rel="noreferrer">
                <img src={terraSvg} alt="TerraForm Labs" />
                <p>terra.money</p>
              </a>
            </div>
            <div className="work__item">
              <a href="https://mcontrol.ml/" target="_blank" rel="noreferrer">
                <img src={mcontrolSvg} alt="MissionControl" />
                <p>mcontrol.ml</p>
              </a>
            </div>
            <div className="work__item">
              <a href="https://ftso.scandinodes.com/" target="_blank" rel="noreferrer">
                <img src={scandinodesSvg} alt="ScandiNodes" />
                <p>ftso.scandinodes.com</p>
              </a>
            </div>
          </div>
        </div>
        <footer className="website__icons__container">
          <a
            href="mailto:alessandro@candeago.dev"
            target="_blank"
            rel="noreferrer"
            className="website__icon"
          >
            <ion-icon name="mail"></ion-icon>
            <p className='big'>alessandro@candeago.dev</p>
          </a>
          <a
            href="https://github.com/alecande11"
            target="_blank"
            rel="noreferrer"
            className="website__icon"
          >
            <ion-icon name="logo-github"></ion-icon>
            <p className='small'>alecande11</p>
          </a>
          <a
            href="https://twitter.com/ale_cande11"
            target="_blank"
            rel="noreferrer"
            className="website__icon"
          >
            <ion-icon name="logo-twitter"></ion-icon>
            <p className='small'>ale_cande11</p>
          </a>
          <a
            href="https://www.linkedin.com/in/alessandro-candeago/"
            target="_blank"
            rel="noreferrer"
            className="website__icon"
          >
            <ion-icon name="logo-linkedin"></ion-icon>
            <p className='big'>alessandro-candeago</p>
          </a>
          <p className='copy'>&copy; {new Date().getUTCFullYear()} Alessandro Candeago. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default Website;
